.header {
  padding: 16px 112px 32px;
}

.underHeader {
  padding: 8px 112px;
}

.underHeaderWrapper {
  background: linear-gradient(180deg, #040506 0%, #262626 100%);
}

.linkContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;
  padding-left: 16px;
}

.logo {
  width: 172px;
  height: 56px;
  max-width: unset;
}

.hamburgerTrigger {
  display: none;
}

.mobileMenuContainer {
  display: none;
}

.mobileLink,
.mobileCart {
  display: none;
}

@media only screen and (max-width: 910px) {
  .linkContainer {
    display: none;
  }

  .mobileCart {
    display: block;
  }

  .hamburgerTrigger {
    display: block;
    z-index: 6;
  }

  .logo {
    width: 172px;
    height: 56px;
    max-width: unset;
  }

  .mobileMenu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.95);
    z-index: 5;
  }

  .header {
    padding: 16px 30px;
  }

  .underHeader {
    padding: 8px 30px;
  }

  .mobileMenuContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .mobileLink {
    display: block;
    padding: 10px;
  }
}
