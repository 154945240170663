.searchBox {
  display: flex;
  max-width: 394px;
}

.searchInput {
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  gap: 17px;

  color: #d4d4d4;

  height: 48px;
  border: unset;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari/Edge */
    color: #d4d4d4;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #d4d4d4;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: #d4d4d4;
  }
}

@media only screen and (max-width: 1200px) {
  .searchBox {
    display: none;
  }
}
