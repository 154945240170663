.partnershipContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 48px 112px;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

@media only screen and (max-width: 910px) {
  .partnershipContainer {
    padding: 20px;
  }

  .logoContainer {
    padding: 16px;
  }
}
