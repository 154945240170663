.container {
  grid-template-columns: 24px 77px auto min-content min-content;
}

.photoContainer {
  height: 80px;
}

@media only screen and (max-width: 530px) {
  .container {
    grid-template-columns: 80px auto min-content;
    grid-template-rows: auto 24px;
    grid-row-gap: 4px;
    grid-column-gap: 8px;
  }

  .photoContainer {
    grid-row: span 2;
    height: 80px;

    img {
      width: 80px;
      max-height: 80px;
    }
  }

  .removeBtnContainer {
    grid-row-start: 2;
    grid-column-start: 3;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .counterContainer {
    grid-column-start: 2;
    grid-row-start: 2;
  }
}
