.contentPadding {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 64px 112px;
  gap: 32px;
}

.footerLinks {
  display: flex;
  flex-direction: row;
  width: 100%;

  & > .leftBlock {
    flex: 0.4;
  }

  & > .rightBlock {
    flex: 0.6;
    display: flex;
    flex-direction: row;

    & > .linkBlock {
      flex: auto;
      display: flex;
      flex-direction: column;

      & > .link {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}

.logo {
  width: 172px;
  height: 56px;
  max-width: unset;
  margin-bottom: 24px;
}

@media only screen and (max-width: 910px) {
  .contentPadding {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 30px;
    gap: 32px;
  }

  .footerLinks {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > .leftBlock {
      flex: 1;
      padding-bottom: 16px;
    }

    & > .rightBlock {
      flex: 1;
      display: flex;
      flex-direction: column;

      & > .linkBlock {
        flex: 1;
        padding: 16px 0 16px 0;
        display: flex;
        flex-direction: column;

        & > .link {
          width: 100%;
          margin-top: 16px;
        }
      }
    }
  }
}
