.titleContainer {
  grid-template-columns: 24px auto 24px;
}

.mobileCloseContainer {
  display: none;
}

@media only screen and (max-width: 530px) {
  .titleContainer {
    margin-top: 16px;

    img {
      display: none;
    }
  }

  .mobileCloseContainer {
    display: unset;

    img {
      filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(0%)
        hue-rotate(195deg) brightness(108%) contrast(100%);
    }
  }

  .drawerContainer {
    top: 0;
    height: 100%;
    display: grid;
    grid-template-rows: min-content auto;
  }

  .container {
    display: grid;
    grid-template-rows: min-content min-content auto min-content min-content min-content min-content min-content;
  }
}
