.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 24px;

  width: 589px;
  min-height: 425.09px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #ffffff;

  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.1);
  border-radius: 4px;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  gap: 335px;

  width: 525px;
  height: 24px;
}

.checkBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 525px;
  height: 48px;
}

.messageBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 335px;

  width: 525px;
  height: 40px;

  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  letter-spacing: -0.02em;

  color: #040506;
}

.separator {
  width: 525px;
  height: 0px;

  border: 1px solid #c4c4c4;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 525px;
  height: 52px;
}

.itemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 525px;
  min-height: 80px;
}

.itemPhoto {
  width: 80px;
  height: 80px;

  img {
    object-fit: contain;
    position: relative !important;
  }
}

.itemTitle {
  width: 212.11px;

  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #040506;
}

.itemPrice {
  width: 172px;
  font-family: 'Helvetica Neue';
  font-style: normal;
}

@media only screen and (max-width: 910px) {
  .content {
    width: 90%;
    height: auto;
  }

  .titleContainer {
    width: 100%;
    height: fit-content;
  }

  .checkBox {
    width: 100%;
  }

  .messageBox {
    width: 100%;
    height: fit-content;
    text-align: center;
  }

  .separator {
    width: 100%;
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .itemContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
  }

  .itemPhoto {
    width: 120px;
    height: 120px;
  }

  .itemTitle {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 20px;
  }

  .itemPrice {
    width: 100%;
    height: fit-content;
    font-size: 20px;
    text-align: center;
  }
}
