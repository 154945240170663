@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  border-color: #f3f3f3;
  border-top-color: #383636;
  border-style: solid;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  display: inline-block;
}

.smallSpinner {
  width: 16px;
  height: 16px;
  border-width: 4px;
}

.mediumSpinner {
  width: 24px;
  height: 24px;
  border-width: 6px;
}

.largeSpinner {
  width: 32px;
  height: 32px;
  border-width: 8px;
}

.text {
  color: #383636;
}

.textSmall {
  font-size: 12px;
}

.textMedium {
  font-size: 16px;
}

.textLarge {
  font-size: 20px;
}
