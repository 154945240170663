.typeWireframe {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #684f23;
  background-color: white;
  color: #684f23;
  font-weight: 500;
  font-size: 18px;
  min-width: 160px;
  min-height: 45px;

  &:hover:enabled {
    background-color: #ececec;
  }

  &:active:enabled {
    background-color: rgb(231, 230, 230);
  }
}

.typeFilled {
  padding: 11px;
  border-radius: 4px;
  background-color: #684f23;
  color: white;
  font-weight: 500;
  font-size: 16px;
  min-height: 45px;
  text-transform: uppercase;

  &:disabled {
    color: #a1a1a1;
  }

  &:hover:enabled {
    background-color: #4a3919;
  }

  &:active:enabled {
    background-color: #332710;
  }
}

.typeCart {
  &:disabled {
    img {
      filter: brightness(0) saturate(100%) invert(65%) sepia(0%) saturate(0%)
        hue-rotate(342deg) brightness(100%) contrast(87%);
    }
  }

  &:hover:enabled {
    img {
      filter: brightness(0) saturate(100%) invert(82%) sepia(35%) saturate(215%)
        hue-rotate(359deg) brightness(87%) contrast(86%);
    }
  }

  &:active:enabled {
    img {
      filter: brightness(0) saturate(100%) invert(74%) sepia(9%) saturate(1307%)
        hue-rotate(0deg) brightness(84%) contrast(87%);
    }
  }
}
