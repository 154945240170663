.btn {
  border: 1.5px solid #c4c4c4;
  color: #6b7280;
  width: 44px;
  height: 44px;
  min-width: unset;
  min-height: unset;

  &:hover:enabled {
    border: 1.5px solid #765a28;
    background-color: unset;

    img {
      filter: brightness(0) saturate(100%) invert(39%) sepia(7%) saturate(4134%)
        hue-rotate(360deg) brightness(84%) contrast(83%);
    }
  }

  &:active:enabled {
    background-color: #4a3919;
    border: 1.5px solid #4a3919;

    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(7500%) hue-rotate(86deg) brightness(101%) contrast(103%);
    }
  }
}
