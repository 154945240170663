@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  overscroll-behavior-y: none;
  background: #040506;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

span.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

input.noBtns::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 1023px) {
  span.anchor {
    display: block;
    position: relative;
    top: -95px;
    visibility: hidden;
  }
}

.swiper {
  padding-bottom: 35px !important;
}

.swiper-pagination-bullet {
  background: transparent !important;
  border: 2px solid #a4a4a4 !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #219653 !important;
  border-color: #219653 !important;
}

.widget_oem_catalog {
  background-color: white;
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.oem_widget_std_block_wrap_img {
  display: none;
}

.widget_oem_catalog .oem_widget_std_block {
  height: 40px !important;
}

.widget_oem_catalog .flex.order-lg2 {
  display: block !important;
}
